<template>
  <div id="login" style="height: 100%; background-color: rgba(255, 255, 255, 0); margin: 0 auto;padding: 0">
  <div class="main-container" style=" background-color: rgba(255, 255, 255, 0)">
    <div class="main" style="opacity: 1 !important; margin: 20px 20px; ">
      <h2 >Автоматизированная Информационная Система Предупреждения о Цунами </h2>
      <H2>(АИСПЦ)</H2>
      <form action="">
      <input
              class="login"
              type="text"
              name="username"
              autocomplete="off"
              id="username"
              placeholder="Логин"
              v-model.trim="username"
      />
      <br />
      <input
              class="login"
              :type="passwordVisible ? 'text' : 'password'"
              name="password"
              id="password"
              autocomplete="on"
              placeholder="Пароль"
              v-model.trim="password"
              @keyup.enter="login(false)"
      />

        <span v-show="password" class="glyphicon eye" @click="passwordVisible = !passwordVisible">&#128065;</span>
        <span v-show="password && passwordVisible" class="glyphicon eye-cross" >&#47;</span>
      <br />
      <input
              type="button"
              :disabled="!username && !password"
              value="Войти"
              class="button login"
              id="done"
              @click="login(false)"
      />

      <br />
      <input
              type="button"
              value="Регистрация"
              class="button login"
              id="registration"
              @click="register"
      />
      <br />
      </form>
    </div>
  </div>
  </div>
</template>

<script>
  import {signin} from '../api/auth-controller'
  import {getItem} from "../utils/persistanceStorage";

  export default {
    name: "Login",
    //Custom style for main and input for make the page responsive:
    props: {
      mainStyle: String,
    },
    data() {
      return {
        username: "",
        password: "",
        passwordVisible: false
      }
    },
    methods: {
      async login(force) {
        // console.log('login', this.username, this.password, force)
        signin(this.username, this.password, force)
          .then(async () => {
            // return getRequests()
            // console.log('auth', getItem('auth'))
            if (getItem('auth')) {
              if (getItem('auth').roles.includes("administrator")) {
                this.$router.push('/users')
              } else if (getItem('auth').roles.includes("oceanographer") || getItem('auth').roles.includes("seismologist") ) {
                this.$router.push('/signals')
              } else if (getItem('auth').roles.includes("expert"))  {
                this.$router.push('/signin-done')
              }
            }
          }).then(result => {
            return result
            // console.log('result', JSON.stringify(result))
        })
          .catch(e => {
            // console.log('catch', e)
            if (e.status === 409) {
              this.forceLoginAsk()
            }
          })
        // this.$router.push('/signin-done')
      },
      async forceLoginAsk() {
          if (await confirm('Пользователь с вашей ролью уже залогинен в систему. вы уверены что хотите заменить его на дежурстве?')) {
             this.login(true)
          }
      },
      register() {
        this.$router.push({path: '/signup2'});
      },
    }
  };
</script>

<style>
  @media screen and (min-width: 800px) {
    .main-container {
      /*background: no-repeat center/100% url("../assets/volna_okean_vsplesk_54955_1280x1280.jpg");*/
      padding: 0;
    }
  }

  .main div.md-field {
    margin-bottom: 10px !important;
  }


  #login {
    background-color: aliceblue;
    background-size: cover;
    width: 100%;
    height: 100%;

  }

  .main-container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightsteelblue;
    padding: 0 20px;
    position: relative;
  }

  .main {
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    border-radius: 3rem;
    box-shadow: 0 0 8px -5px #000000;
    width: 750px;
    margin: auto 20px;
    padding: 10px;
    /*padding: 60px;*/
  }


  h1 {
    cursor: default;
    user-select: none;
  }

  input.login {
    border-radius: 3rem;
    border: none;
    padding: 10px;
    text-align: center;
    outline: none;
    margin: 10px;
    box-sizing: border-box;
    font-weight: 400;
    width: 400px;
    max-width: 90vw;
  }

  input:hover {
    box-shadow: 0px 0px 8px -5px #000000;
  }

  input:active {
    box-shadow: 0px 0px 8px -5px #000000;
  }

  #done, #registration {
    background: #98D0E8;
  }

  .button {
    cursor: pointer;
    user-select: none;
  }

  img {
    height: 2.2rem;
    margin: 10px;
    user-select: none;
  }

  img:hover {
    box-shadow: 0px 0px 8px -5px #000000;
    cursor: pointer;
    border-radius: 200rem;
  }
</style>
